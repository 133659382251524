// class Tab {
//   constructor() {
//     this.tab_button = document.querySelectorAll('.js-tab-button');
//   }

//   init() {
//     this.attachEvent();
//   }

//   attachEvent() {
//     for(const button of this.tab_button) {
//       button.addEventListener('click', (e) => {
//         let id = button.getAttribute('aria-controls');
//         this.hideContents(id, e);
//         this.showContent(id, e);
//       });
//     }
//   }

//   hideContents(id, e) {
//     //クリックしたタブ、パネルの親を取得
//     let button_parent = e.target.closest('.js-tab-list');
//     let panel_parent = document.querySelector(`#${id}`).closest('.js-tab-panel-wrapper');

//     //表示状態のボタン、パネルの状態を取得
//     let active_button = button_parent.querySelector('.js-tab-button[aria-selected="true"]');
//     let active_panel = panel_parent.querySelector('.js-tab-panel[aria-hidden="false"]');

//     //タブボタン
//     active_button.setAttribute('aria-selected', 'false');
//     //タブパネル
//     active_panel.setAttribute('aria-hidden', 'true');
//   }

//   showContent(id, e) {
//     //タブボタン
//     e.target.closest('.js-tab-button').setAttribute('aria-selected', 'true');
//     //タブパネル
//     document.querySelector(`#${id}`).setAttribute('aria-hidden', 'false');
//   }

// }

// const tab = new Tab();
// tab.init();


// // const tab_button = document.querySelectorAll('.js-tab-button');

// // for(const button of tab_button) {
// //   button.addEventListener('click', (e) => {

// //     //クリックしたボタンからパネルのidを取得 (aria-controlsの値)
// //     let panel_id = button.getAttribute('aria-controls');

// //     //クリックしたタブ、それに紐付くパネルの親を取得
// //     let button_parent = e.target.closest('.js-tab-list');
// //     let panel_parent = document.querySelector(`#${panel_id}`).closest('.js-tab-panel-wrapper');

// //     //表示状態のボタン、パネルの状態を取得
// //     let active_button = button_parent.querySelector('.js-tab-button[aria-selected="true"]');
// //     let active_panel = panel_parent.querySelector('.js-tab-panel[aria-hidden="false"]');

// //     //ボタンの状態を変更
// //     active_button.setAttribute('aria-selected', 'false');
// //     button.setAttribute('aria-selected', 'true');

// //     //パネルの状態を変更
// //     active_panel.setAttribute('aria-hidden', 'true');
// //     document.querySelector(`#${panel_id}`).setAttribute('aria-hidden', 'false');

// //   });
// // }

