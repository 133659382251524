import swiper from "swiper";



//--------------------------------------------------------
//top-heroスライド
//--------------------------------------------------------
var slideCount = $('.top-hero').find(".swiper-slide").length;
if (slideCount > 1) {
  var serviseNav = new swiper(".top-hero-slider", {
    direction: "horizontal",
    loop: true,
    speed: 1500,
    autoplay: true,
    spaceBetween: 0,
    centeredSlides: true,
    observer: true,
    allowTouchMove: true,
    preloadImages: false,
    autoHeight: true,
    slidesPerView: 1,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 2,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      // 960: {
      //   slidesPerView: 2
      // },
      // 414: {
      //   autoHeight: false,
      //   slidesPerView: 1
      // }
    },
    navigation: {
      nextEl: ".top-hero-slider-next",
      prevEl: ".top-hero-slider-prev",
    }
  });
} else {
  $('.top-hero').find(".top-hero-slider-next").hide();
  $('.top-hero').find(".top-hero-slider-prev").hide();
}


//--------------------------------------------------------
//top-eventスライド
//--------------------------------------------------------

//const breakPoint = 1200;
let mySwiper2;
let mySwiperBool2;
let timeoutId;
let slideCount2 = $('.top-event').find(".swiper-slide").length;
let initSwiper = function() {
  if (slideCount2 > 2) {
    mySwiper2 = new swiper(".top-event-slide", {
      slidesPerView: 'auto',
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      // onSlideChangeEnd : (s) => {s.fixLoop();},
      spaceBetween: 0,
      navigation: {
        nextEl: ".top-event-slide__next",
        prevEl: ".top-event-slide__prev",
      },
    });
  } else {
    $('.top-event').find(".swiper-slide").addClass("swiper-slide-active");
    $('.top-event').find(".top-event-slide__next").hide();
    $('.top-event').find(".top-event-slide__prev").hide();
  }
}



//--------------------------------------------------------
//top-collectionスライド
//--------------------------------------------------------
let mySwiper3;
let timeoutId03;
let slideCount3 = $('.top-collection').find(".swiper-slide").length;
let initSwiper3 = function() {
  if (slideCount3 > 1) {
    mySwiper3 = new swiper(".top-collection-contents__slide", {
      loop: true,
      slidesPerView: 1,
      loopAdditionalSlides: slideCount3,
      centeredSlides: true,
      allowTouchMove: false,
      speed: 25000,
      autoplay: {
        delay: 0,
      },
      onSlideChangeEnd : (s) => {s.fixLoop();},
    });
  } else {
    $('.top-collection').find(".swiper-slide").addClass("swiper-slide-active");
  }
}
/*--------------------------------
ロード時
---------------------------------*/
window.addEventListener('load',()=>{
  initSwiper();
  initSwiper3();
},false);

/*--------------------------------
リサイズ時
---------------------------------*/
window.addEventListener('resize',()=>{
  //mySwiper2.destroy(false,true);
  mySwiper2 = "";
  mySwiper3 = "";
  clearTimeout( timeoutId ) ;
	timeoutId = setTimeout( function () {
    initSwiper();
    initSwiper3();
  }, 500 ) ;
},false);
