import swiper from "swiper";

const breakPointExhibition = 768;
let mySwiperExhibition01;
let eventSlideCount = $('.exhibition-single-body__slide-list').find(".swiper-slide").length;

/*--------------------------------
ロード時
---------------------------------*/
window.addEventListener('load',()=>{
  if( breakPointExhibition < window.innerWidth ){//pc
    if (mySwiperExhibition01) {
      mySwiperExhibition01.destroy();
      mySwiperExhibition01 = undefined;
    } 
  } else {
    if(eventSlideCount > 1){//sp
      if (mySwiperExhibition01) {
        return;
      } else {
        mySwiperExhibition01 = new swiper ('.exhibition-single-body__slide', {
          slidesPerView: 1,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          slidesPerView: "auto",
          loop: true,
          onSlideChangeEnd : (s) => {s.fixLoop();},
          spaceBetween: 20,
          //loopedSlides: 1,
          centeredSlides : true,
          disableOnInteraction: true,
          slideToClickedSlide: true,
          pagination: {
            el: '.exhibition-single-body__slide-list-item-pagination',
            clickable: true
          }
        });
      }
    }
  }
},false);

/*--------------------------------
リサイズ時
---------------------------------*/
window.addEventListener('resize',()=>{
  if( breakPointExhibition < window.innerWidth ){//pc
    if (mySwiperExhibition01) {
      mySwiperExhibition01.destroy();
      mySwiperExhibition01 = undefined;
    } 
  } else {
    if(eventSlideCount > 1){//sp
      if (mySwiperExhibition01) {
        return;
      } else {
        mySwiperExhibition01 = new swiper ('.exhibition-single-body__slide', {
          slidesPerView: 1,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          slidesPerView: "auto",
          loop: true,
          onSlideChangeEnd : (s) => {s.fixLoop();},
          spaceBetween: 20,
          //loopedSlides: 1,
          centeredSlides : true,
          disableOnInteraction: true,
          slideToClickedSlide: true,
          pagination: {
            el: '.exhibition-single-body__slide-list-item-pagination',
            clickable: true
          }
        });
      }
    }
  }
},false);







const breakPointEvent = 768;
let mySwiperEvent01;
let eventSlideCount2 = $('.event-single-body__slide-list').find(".swiper-slide").length;

/*--------------------------------
ロード時
---------------------------------*/
window.addEventListener('load',()=>{
  if( breakPointEvent < window.innerWidth ){//pc
    if (mySwiperEvent01) {
      mySwiperEvent01.destroy();
      mySwiperEvent01 = undefined;
    } 
  } else {
    if(eventSlideCount2 > 1){//sp
      if (mySwiperEvent01) {
        return;
      } else {
        mySwiperEvent01 = new swiper ('.event-single-body__slide', {
          slidesPerView: 1,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          slidesPerView: "auto",
          loop: true,
          onSlideChangeEnd : (s) => {s.fixLoop();},
          spaceBetween: 20,
          //loopedSlides: 1,
          centeredSlides : true,
          disableOnInteraction: true,
          slideToClickedSlide: true,
          pagination: {
            el: '.event-single-body__slide-list-item-pagination',
            clickable: true
          }
        });
      }
    }
  }
},false);

/*--------------------------------
リサイズ時
---------------------------------*/
window.addEventListener('resize',()=>{
  if( breakPointEvent < window.innerWidth ){//pc
    if (mySwiperEvent01) {
      mySwiperEvent01.destroy();
      mySwiperEvent01 = undefined;
    } 
  } else {
    if(eventSlideCount2 > 1){//sp
      if (mySwiperEvent01) {
        return;
      } else {
        mySwiperEvent01 = new swiper ('.event-single-body__slide', {
          slidesPerView: 1,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          slidesPerView: "auto",
          loop: true,
          onSlideChangeEnd : (s) => {s.fixLoop();},
          spaceBetween: 20,
          //loopedSlides: 1,
          centeredSlides : true,
          disableOnInteraction: true,
          slideToClickedSlide: true,
          pagination: {
            el: '.event-single-body__slide-list-item-pagination',
            clickable: true
          }
        });
      }
    }
  }
},false);

