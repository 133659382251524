// import isMobile from "../../plugins/is-mobile.js";

// if($('.js-overlaywindow').length) {
//   document.addEventListener("click", e => {
//     if (!e.target.closest(".js-overlaywindow")) {
//       if (!e.target.closest(".overlaywindow-wrapper")) {
//         $("body,html").css({
//           position: "static",
//           overflow: "visible",
//           height: "auto"
//         });
//         setTimeout(function() {
//           $(".overlaywindow-container").removeClass("is-overlaywindow--show");
//           $("body").removeClass("is-overlaywindow--showPage");
//         }, 100);
//         // メニューを開いた時点のスクロール位置を反映
//         if($('.is-overlaywindow--showPage').length) {
//           $(window).scrollTop($("html").data("scrTop")); //メニューを開いた位置へ移動
//         }
//       } else {
//       }
//     }
//   });
// }


// $(".js-overlaywindow")
//   .add(".js-overlaywindow-close")
//   .on("click", function() {
//     var overlaywindow_num = $(this).data("overlaywindow");
//     // console.log(overlaywindow_num);
//     if (!$("body").hasClass("is-overlaywindow--showPage")) {
//       $(
//         '.overlaywindow-container[data-overlaywindow="' +
//           overlaywindow_num +
//           '"] '
//       ).addClass("is-overlaywindow--show");
//       $(this).addClass('is-current');
//       $("body")
//         .removeClass("is-searchContent--hidePage")
//         .addClass("is-overlaywindow--showPage");

//       // 現在のスクロール位置を取得
//       $("html").data("scrTop", $(window).scrollTop()); //メニューを開いた位置を保存
//       $("body,html").css({
//         position: "fixed",
//         top: -1 * $("html").data("scrTop"),
//         height: "100%"
//       });
//     } else if(!$(this).hasClass('is-current')) {
//       $(
//         '.overlaywindow-container'
//       ).removeClass("is-overlaywindow--show");
//       $(
//         '.overlaywindow-container[data-overlaywindow="' +
//           overlaywindow_num +
//           '"] '
//       ).addClass("is-overlaywindow--show");
//       $(".js-overlaywindow").removeClass('is-current');
//       $(this).addClass('is-current');
//     } else {
//       $("body,html").css({
//         position: "static",
//         overflow: "visible",
//         height: "auto"
//       });
//       $(".js-overlaywindow").removeClass('is-current');
//       setTimeout(function() {
//         $(
//           '.overlaywindow-container[data-overlaywindow="' +
//             overlaywindow_num +
//             '"] '
//         ).removeClass("is-overlaywindow--show");
//         $("body").removeClass("is-overlaywindow--showPage");
//       }, 100);
//       // メニューを開いた時点のスクロール位置を反映
//       $(window).scrollTop($("html").data("scrTop")); //メニューを開いた位置へ移動
//     }
//   });

// //リンク無効
// // $(".js-overlaywindow").on("click", function(e) {
// //   return !$(e.target).is("a");
// // });
