//開発版
// import Vue from "vue/dist/vue.esm.js";
//本番用
import Vue from "vue/dist/vue.min.js";

import swiper from "swiper";

import Tab from '../../plugins/tab.js';
import SmartPhoto from "../../plugins/smartphoto.js";
import modal from '../../plugins/modal.js';


//API共通部分
//本番
const API_ROOT = "https://api.jmapps.ne.jp/db";
//テスト
// const API_ROOT = "https://api-test.jmapps.ne.jp/db/";

//収蔵品検索結果のパス
const COLLECTION_RESULT = "/collection/result";
//詳細のパス
const COLLECTION_SINGLE = "/collection/single";


//分野別前一覧検索のURL
const COLLECTION_RESULT_FIELDS = {
    HISTORY:`${COLLECTION_RESULT}?type=history`,
    ARCHEOLOGY:`${COLLECTION_RESULT}?type=archeology`,
    ART:`${COLLECTION_RESULT}?type=art`,
    NATION:`${COLLECTION_RESULT}?type=nation`,
    NATURE:`${COLLECTION_RESULT}?type=nature`,
}


//町別検索のURL
const COLLECTION_RESULT_TOWN = {
    IZUHARA:`${COLLECTION_RESULT}?keywords=厳原町`,
    MITSUSHIMA:`${COLLECTION_RESULT}?keywords=美津島町`,
    TOYOTAMA:`${COLLECTION_RESULT}?keywords=豊玉町`,
    KAMIAGATA:`${COLLECTION_RESULT}?keywords=上県町`,
    MINE:`${COLLECTION_RESULT}?keywords=峰町`,
    KAMITSUSHIMA:`${COLLECTION_RESULT}?keywords=上対馬町`,
};



//セクレターに使用する検索項目
//歴史
const LIST_HISTORY = [
    { searchItem:"時代", name:"f12", select: "", option:["平安時代以前","鎌倉時代","室町時代","安土桃山時代","江戸時代","明治時代","大正時代","昭和時代","平成時代以降"] },
    { searchItem:"資料種別", name:"f2", select: "", option:["古文書","古記録","古典籍","経典","絵図","絵巻","印章","近代資料","古写真","その他"] },
    { searchItem:"資料形態", name:"f3", select: "", option:["一紙","折紙","冊子","折本","掛幅","巻子","屏風","洋装本","その他"] },
    { searchItem:"資料群名", name:"f4", select: "", option:["浅野家文書","大浦家文書","小田家文書","小林家文書","主藤壽家文書","藤家文書","中江家文書","満山家文書","吉村家文書","その他"] }
];

//考古
const LIST_ARCHEOLOGY = [
    { searchItem:"時代区分", name:"f7", select: "", option:["縄文時代","弥生時代","古墳時代","古代","中世","近世","近代","現代"] },
    { searchItem:"資料種別", name:"f2", select: "", option:["土器","石器","鉄器","青銅器","木器","骨角牙貝製品","ガラス製品","陶器","磁器","須恵器","土師器","陶質土器","石製品"] },
    { searchItem:"制作地", name:"f3", select: "", option:["日本","朝鮮半島","中国","東南アジア","その他"] },
];

//美術
const LIST_ART = [
    { searchItem:"産地", name:"f5", select: "", option:["対馬","日本","朝鮮半島","中国","東南アジア","その他"] },
    { searchItem:"時代", name:"f12", select: "", option:["古代","平安時代","鎌倉時代","室町時代","安土桃山時代","江戸時代","明治時代","大正時代","昭和時代","平成時代以降"] },
    { searchItem:"分類", name:"f2", select: "", option:["絵画","彫刻","工芸","写真","その他"] },
    { searchItem:"資料種別", name:"f3", select: "", option:["油彩","日本画","水彩","アクリル","素描","書跡","版画","彫刻","仏像","陶磁","金工","漆工","染織","写真","映像","その他"] },
    { searchItem:"資料形態", name:"f7", select: "", option:["掛軸","巻子","屏風","襖","画布","板絵","写真","その他"] }
];

//民俗
const LIST_NATION = [
    { searchItem:"収集場所", name:"f4", select: "", option:["上対馬町","上県町","峰町","豊玉町","美津島町","厳原町"] },
    { searchItem:"時代", name:"f3", select: "", option:["明治時代","大正時代","昭和時代","平成時代以降","その他"] },
    { searchItem:"分類", name:"f2", select: "", option:["台所用品","装身具","農具","狩猟・採集用具","染織用具","運搬具","冠婚葬祭","信仰行事","娯楽","その他"] }
];

//自然史
const LIST_NATURE = [
    { searchItem:"資料種別", name:"f2", select: "", option:["昆虫標本","植物標本","骨格標本","剥製標本","液浸標本","プレパラート","岩石標本","化石標本"] },
    { searchItem:"資料形態", name:"f9", select: "", option:["乾燥標本","腊葉標本","交連骨格","分離骨格","本剥製","仮剥製","その他"] },
    { searchItem:"採取地", name:"f6", select: "", option:["厳原町","美津島町","豊玉町","峰町","上県町","上対馬町","島外","国外"] },
    { searchItem:"分類群", name:"f10", select: "", option:["昆虫","植物","哺乳類","鳥類","両生類","爬虫類","魚類","その他無脊椎動物","その他"] }
];

//町別検索
const SEARCH_BY_TOWN = {
    town:["厳原町","美津島町","豊玉町","峰町","上県町","上対馬町","島外","国外"],
    category:["歴史", "考古", "美術", "民俗", "自然史"],
};

//キーワード再検索
const RESEARCH_KEYWORD = {
    town:["厳原町","美津島町","豊玉町","峰町","上県町","上対馬町","島外","国外"],
    category:["歴史", "考古", "美術", "民俗", "自然史"],
};

//収蔵品詳細の表示項目
const LIST_SINGLE = {
    history:["f2","f3","f4","f5","f6","f7","f8","f9","f10","f11","f12"],
    archeology:["f2","f3","f4","f5","f6","f7","f8","f9","f10","f11","f12","f13","f14","f15"],
    art:["f3","f4","f5","f6","f7","f8","f9","f10","f12"],
    nation:["f3","f4","f5","f6","f7","f8","f9"],
    nature:["f2","f3","f4","f6","f8","f9","f10"],
};

//画像なしの場合の画像
const COLLECTION_SINGLE_IMAGE_TEMPLATE = [
    {
        title:"画像なし",
        url:"/assets/img/collection/noimage.png"       
    }
];

Vue.component('result-list', {
    data: function () {
      return {
        count: 0
      }
    },
    props:["result_data", "result_page"],
    template:`
    <div class="p-search-result-list">
        <div class="p-search-result-list__item" v-for="(item, index) in result_data" :key="index">
            <a :href="getSinglePageLink(item)">
                <figure class="p-search-result-list__image">
                    <img :src="getListImage(item)" alt="">
                </figure>
                <div class="p-search-result-list__detail">
                    <h3 class="p-search-result-list__title" v-html="item.title || 'タイトルなし'">
                    </h3>
                    <div class="p-search-result-list-tags">
						<span v-for="(tag, index) in resultPageTags(item)" :key="index">
							{{tag}}<span class="p-search-result-list-tags__pipe" v-show="index + 1 != resultPageTags(item).length">/</span>
						</span>						
                    </div>
                </div>
                <span class="myfont myfont-arrow-right"></span>
            </a>
        </div>
    </div>
    `,
    methods: {
        getSinglePageLink(item) {
            const anchor = `${item.anchorType && "type=" || ""}${item.anchorType || ""}`;
            const data_id = `&data_id=${item.data_id}`;
            const page_num = `&page_num=${this.result_page.current}`;
            const fields = `&fields=${this.result_page.type && 1 || 0}`;
            const query = `${this.result_page.query && "&query=" || ""}${this.result_page.query.replaceAll("&", "-")}`;
            if(item.anchorType == undefined) {
                return "";
            }
            return `${COLLECTION_SINGLE}?${anchor}${data_id}${page_num}${fields}${query}`;
        },
        getListImage(item) {
            return item.pict_data && item.pict_data.data && item.pict_data.data[0] || "/assets/img/collection/noimage.png";
        },  
        resultPageTags(item) {
            const list = this.$parent.getListByType(item.anchorType);

            if(list.data) {
                return list.data.map(d => {
                    const {searchItem, name} = d;
                    const key = searchItem;
                    const value = item[name] || 'ー';

                    return {key, value};
                })
                .map(d => `${d.key}：${d.value}`)
            }
        },
    }
  })



new Vue({
    el:".greeting-contents",
    data: {
        COLLECTION_RESULT_TOWN,
        COLLECTION_RESULT_FIELDS,
        LIST_HISTORY,
        LIST_ARCHEOLOGY,
        LIST_ART,
        LIST_NATION,
        LIST_NATURE,
        LIST_SINGLE,
        SEARCH_BY_TOWN,
        RESEARCH_KEYWORD,
        COLLECTION_SINGLE_IMAGE_TEMPLATE,
        resultData:[],
        search:{
            byKeyword:{
                keywords:"",
            },
            byTown:{
                town:"",
                category:"",
            }
        },
        resultPage:{
            current:1,
            hitCount:0,
            type:"",
            anchor:[],
            prevAnchor_num:0,
            nextAnchor_num:0,
            anchorType:"",
            query:"",
            keywords:[],
            research:{
                town:"",
                category:"",
                keywords:""
            }
        },
        singleData:{
            title:"",
            data:[],
            pict_data:[],
            fields:0,
            type:"",
            query:"",
            page_num:"",
            show_swiper:false,
        }
    },
    methods: {

        searchByField(data,type) { 
            const href = this.makeResultLink(data, type);  
            window.location.href = href;
        },

        makeResultLink(data,type){
            const query = data.filter(d => d.select !== "").map(d => `${d.name}=${d.select}`).join("&")
            return `${COLLECTION_RESULT}?type=${type}&page_num=1&${query}`;
        },

        searchByTown() {
            const { town, category } = this.search.byTown;
            const list = this.getListByType(category);

            if(list && (town != "" || category != "")) {
                const type = list.type_en;
                
                const href = `${COLLECTION_RESULT}?type=${type}&page_num=1${town && "&keywords="}${town}`;

                window.location.href = href;

            }
        },
        searchByKeyword() {
            const keywords = this.search.byKeyword.keywords;
            if(keywords) {
                const href = `${COLLECTION_RESULT}?page_num=1${keywords && "&keywords="}${keywords}`;
                window.location.href = href;

            }
        },
        researchByKeyword() {
            const { category, town, keywords } = this.resultPage.research || "";
            const list = this.getListByType(category);
            if(category || town || keywords) {
                const base = `${COLLECTION_RESULT}?page_num=1`;
                const query = `${list.type_en && "&type="}${list.type_en}`;
                const key = [keywords, town].filter(s => s != "").join("+");
                const href = `${base}${query}${key && "&keywords="}${key}`;
                window.location.href = href;
            }
        },
        resetSelect(list) {
            list.forEach(data => {
                data.select = "";
            });
        },
        clearTown(){
            this.search.byTown.town = "";
            this.search.byTown.category = "";
        },
        clearKeyword(){
            this.search.byKeyword.keywords = "";
        },
        clearResearch(){
            this.resultPage.research.category = "";
            this.resultPage.research.town = "";
            this.resultPage.research.keywords = "";
        },
        getListByType(type) {
            const incluedsType = (type, targets) => targets.some(t => t == type); 
            if(incluedsType(type, ["history", "歴史"])) {
                return {type:"歴史", type_en:"history", num:"shiryo_01", data:this.LIST_HISTORY };
            }
            if(incluedsType(type, ["archeology", "考古"])) {
                return {type:"考古", type_en:"archeology", num:"shiryo_05", data:this.LIST_ARCHEOLOGY };
            }
            if(incluedsType(type, ["art", "美術"])) {
                return {type:"美術", type_en:"art", num:"shiryo_02", data:this.LIST_ART };
            }
            if(incluedsType(type, ["nation", "民俗"])) {
                return {type:"民俗", type_en:"nation", num:"shiryo_03", data:this.LIST_NATION };
            }
            if(incluedsType(type, ["nature", "自然史"])) {
                return {type:"自然史", type_en:"nature", num:"shiryo_08", data:this.LIST_NATURE };
            }
            return {data:[], type:"", type_en:""};
        },

    },
    computed: {
        resultHitCount() {
            return this.resultPage.hitCount;
        },

        pageAnchor() {
            let params = new URLSearchParams(location.search);
            let keywords = "";
            const type = params.get("type");
            const list = this.getListByType(type);
            const query = list.data
                            .filter(d => params.has(d.name))
                            .map(d => `${d.name}=${params.get(d.name)}`)
                            .join("&");

            if(params.has("keywords")) {
                keywords = params.get("keywords");
            }
            this.resultPage.query = `${keywords && "&keywords="}${keywords}${query && "&"}${query}`;

            if(this.resultPage.anchor.length == 0) {
                return [
                    { 
                        page_num:1,
                        href: `${COLLECTION_RESULT}/?${this.resultPage.anchorType && "type="}${this.resultPage.anchorType}&page_num=1${this.resultPage.query}`
                    }
                ];
            }

            return this.resultPage.anchor
                .map(data => {
                    data.href = `${COLLECTION_RESULT}/?${this.resultPage.anchorType && "type="}${this.resultPage.anchorType}&page_num=${data.page_num}${this.resultPage.query}`;
                    return data;
                });

        },

        resultPrevPage() {
            return `${COLLECTION_RESULT}/?type=${this.resultPage.anchorType}${this.resultPage.prevAnchor_num && "&page_num="}${this.resultPage.prevAnchor_num}${this.resultPage.query}`;
        },

        resultNextPage() {
            return `${COLLECTION_RESULT}/?type=${this.resultPage.anchorType}${this.resultPage.nextAnchor_num && "&page_num="}${this.resultPage.nextAnchor_num}${this.resultPage.query}`;
        },

        listSingleItems() {
            if(this.singleData.data.length > 0) {
                return this.singleData.data.map(d => `<dt>${d.name}</dt><dd>${d.value}</dd>`).join("\n");
            }

            return `<dt></dt><dd></dd>`;
        },

        getPrevPage() {
            const fields = this.singleData.fields != 0 ? this.singleData.fields : "";
            const type = this.singleData.type;
            const query = this.singleData.query;
            const page_num = this.singleData.page_num;
            
            return `${COLLECTION_RESULT}?${fields && "type="}${fields && type}&page_num=${page_num}${query.replaceAll("-", "&")}`;
        }

    },

    async mounted() {

        setTimeout(() => {
            document.querySelectorAll(".js-search-value").forEach(d => d.value = "")
        },500)

        const location = window.location;
        const path = location.pathname;

        if(path.includes(COLLECTION_RESULT)) {
            let params = new URLSearchParams(location.search);
            const type = params.get("type");
            const list = this.getListByType(type);
            const page = params.get("page_num") || 1;
            let keywords = "";
            this.resultPage.type = list.type || "";
            this.resultPage.anchorType = type || "";

            if(list.type || params.has("keywords")) {
                const arr_param = list.data.filter(d => params.has(d.name))
                            
                this.resultPage.queries = arr_param.map(d => d.option[params.get(d.name)]);

                const query = arr_param
                                .map(d => `${d.name}~*${d.option[params.get(d.name)]}`)
                                .join(";")

                if(params.has("keywords")) {
                    keywords = params.get("keywords").replaceAll(" ", "+");
                }
                const url = `${API_ROOT}/0227/1/${list.num || "shiryo"}?page=${page}${query && "&q="}${query}${keywords && "&keywords="}${keywords}`;
            
                // console.log(url)

                const result = await fetch(url)
                .then(r =>  r.json().then(data => ({status: r.status, body: data})).catch(() =>  ({status:400})))
                .then(res => {
                    if(res.status == 200) {
                        const data = res.body;
                        this.resultData = [...this.resultData, ...data.data];
                        this.resultPage.current = data.page || 1;
                        const maxPage = Math.ceil(data.hit_count / data.count_per_page) || 1;

                        if(maxPage == 1) {
                            this.resultPage.anchor = [{page_num:data.page, current:true}];
                            this.resultPage.prevAnchor_num = 0;
                            this.resultPage.nextAnchor_num = 0;
                        }
                        else if(data.page == 1) {
                            this.resultPage.anchor = [ {page_num:data.page, current:true}, {page_num:data.page + 1, current:false}];
                            this.resultPage.prevAnchor_num = 0;
                            this.resultPage.nextAnchor_num = data.page + 1;
                        }
                        else if(data.page == maxPage) {
                            this.resultPage.anchor = [{page_num:data.page - 1, current:false}, {page_num:data.page, current:true}];
                            this.resultPage.prevAnchor_num = data.page - 1;
                            this.resultPage.nextAnchor_num = 0;
                        }
                        else {
                            this.resultPage.anchor = [{page_num:data.page - 1, current:false }, {page_num:data.page, current:true }, {page_num:data.page + 1, current:false }];
                            this.resultPage.prevAnchor_num = data.page - 1;
                            this.resultPage.nextAnchor_num = data.page + 1;
                        }

                        
                        this.resultPage.hitCount = data.hit_count;

                        return this.resultData;
                    }
                    return [];
                })
                //分類がない場合はshiryo/資料番号から分類を取得して分類と
                if(!list.type) {
                    const arr_url = result.map(d => `${API_ROOT}/0227/1/shiryo/${d.data_id}`)
                    const ArrayfilterdStatusSuccess = arr_obj => arr_obj.filter(obj => obj.status == 200);
                    const trimResponseData = r => r.json().then(data => ({status: r.status, body:data.data}))

                    Promise.all(
                        arr_url.map(async u => fetch(u).then(trimResponseData))
                    )
                    .then(ArrayfilterdStatusSuccess)
                    .then(arr_response => arr_response.map(res => {
                        const data = res.body;
                        const list = this.getListByType(data.f2);
                        const url = `${API_ROOT}/0227/1/${list.num}/${data.data_id}`;
                        const anchorType = list.type_en;

                        return {url, anchorType};
                    }))
                    .then(arr_response => 
                        Promise.all(
                            arr_response.map(res => fetch(res.url).then(trimResponseData).then(r => Object.assign(r.body, {anchorType:res.anchorType})))
                        )
                    )
                    .then(arr_res => arr_res.map(res => {
                        const data = res;
                        const index =  this.resultData.map(d => d.data_id).indexOf(data.data_id);

                        if(index != -1) {
                            this.resultData[index] = Object.assign({}, this.resultData[index], data, {anchorType:res.anchorType})
                            this.resultData.splice();
                            return this.resultData[index];
                        }
                    }))
                }
                else {
                    this.resultData.map(data => Object.assign(data, {anchorType:this.resultPage.anchorType }));
                    this.resultData.splice();
                }
            }

            const elements = document.querySelectorAll(".js-tab");
            for (const element of elements) {
                new Tab(element, {
                    defaultOpenPanel: 0
                });
            }    
        }

        if(path.includes(COLLECTION_SINGLE)) {
            let params = new URLSearchParams(location.search);
            const data_id = params.get("data_id");
            const type = params.get("type");
            const fields = params.get("fields") || "";
            const query = params.get("query") || "";
            const page_num = params.get("page_num") || 1;
            const list = this.getListByType(type);

            this.singleData = Object.assign({}, this.singleData, {fields, type, query, page_num});

            if(data_id && list.data.length) {
                // const url = "https://api.jmapps.ne.jp/db/0169/1/shiryo_02/51";
                const url = `${API_ROOT}/0227/1/${list.num}/${data_id}`;
                // console.log(url)
                await fetch(url)
                .then(r =>  r.json().then(data => ({status: r.status, body: data})))
                .then(res => {
                    if(res.status == 200) {
                        const data = res.body;
                        this.singleData.title = data.data.title;
                        this.singleData.data = this.LIST_SINGLE[type]
                        .map(t => ({name:data.cols[t], value:data.data[t] || "ー"}) )
                        
                        this.singleData.pict_data = data.data.pict_data && data.data.pict_data.data || [];
                    }
                    this.singleData.show_swiper = true;
                })
                .then(() => {
                    let slider = document.querySelector(".js-search-single-main");

                    if (slider) {
                    var slideCount = slider.querySelectorAll(".swiper-slide").length;
                    }

                    if (slideCount > 1) {
                        const searchMain = new swiper(".js-search-single-main", {
                            direction: "horizontal",
                            loop: false,
                            speed: 1500,
                            // autoplay: true,
                            spaceBetween: 0,
                            centeredSlides: true,
                            observer: true,
                            allowTouchMove: true,
                            preloadImages: false,
                            autoHeight: true,
                            slidesPerView: 1,
                            lazy: {
                            loadPrevNext: true,
                            loadPrevNextAmount: 2,
                            },
                            breakpoints: {
                            // 414: {
                            //   autoHeight: false,
                            //   slidesPerView: 1
                            // }
                            },
                            navigation: {
                            nextEl: ".p-search-single-slider-main-next",
                            prevEl: ".p-search-single-slider-main-prev",
                            },
                        });

                        const searchSub = document
                            .querySelector(".js-search-single-sub")
                            .querySelectorAll(".p-search-single-slider-sub__item");

                        searchSub.forEach((e, i) => {
                            e.addEventListener("click", () => {
                                searchMain.slideTo(i);
                            });
                        });
                    } else if(slider || slideCount < 1) {
                        document.querySelector(".p-search-single-slider-main-next").style.display =
                            "none";
                        document.querySelector(".p-search-single-slider-main-prev").style.display =
                            "none";

                        document.querySelector(".js-search-single-sub").style.display = "none";
                    }

                    new SmartPhoto(".js-smartPhoto", {
                        resizeStyle: "fit"
                    });
                      
                    modal.init();
                });   
            }
        }
    }
})
