import swiper from "swiper";

//--------------------------------------------------------
//top-eventスライド
//--------------------------------------------------------

//const breakPoint = 1200;
let mySwiper4;
let mySwiperBool4;
let timeoutId;
let slideCount4 = $(".know-spots").find(".swiper-slide").length;
let initSwiper = function () {
  if (slideCount4 > 1) {
    mySwiper4 = new swiper(".know-spots-slide", {
      slidesPerView: "auto",
      // slidesPerView: 7,
      loop: false,
      speed: 1000,
      // autoplay: {
      //   delay: 2000,
      //   disableOnInteraction: false,
      // },
      onSlideChangeEnd: (s) => {
        s.fixLoop();
      },
      spaceBetween: 20,
      navigation: {
        nextEl: ".know-spots-slide__next",
        prevEl: ".know-spots-slide__prev",
      },
      disableOnInteraction: true,
      // slideToClickedSlide: true,
      breakpoints: {
        768: {
          slidesPerView: 1.38,
          centeredSlides: true,
          spaceBetween: 10,
          // disableOnInteraction: true,
          // slideToClickedSlide: true,
        },
      },
    });
  } else {
    $(".know-spots").find(".know-spots-slide__next").hide();
    $(".know-spots").find(".know-spots-slide__prev").hide();
  }
};

$(".c-draw-border").each(function () {
  $(this).html(
    '<span class="icon">' +
      $(this).html() +
      '</span><span class="circle"><span><em></em></span><span><em></em></span></span>'
  );
});

/*--------------------------------
ロード時
---------------------------------*/
window.addEventListener(
  "load",
  () => {
    initSwiper();
  },
  false
);

/*--------------------------------
リサイズ時
---------------------------------*/
window.addEventListener(
  "resize",
  () => {
    mySwiper4 = "";
    clearTimeout(timeoutId);
    timeoutId = setTimeout(function () {
      initSwiper();
    }, 500);
  },
  false
);
