const moment = require("moment");

/*--------------------------------
ロード時
---------------------------------*/
window.addEventListener('load',()=>{
  //console.log(1111);
  $('.schedule-contents-event__wrap li').each(function(index) {
    $(this).addClass('-event' + index);
    let d = new Date();
    let year = d.getFullYear();
        year = String(year);
    
    let myClass = $(this).attr('class');
    let class_Array = myClass.split(" ");
    let myRoom = '.' + class_Array[2] + ' .schedule-contents-event__item-term';
    let myTitle = '.' + class_Array[2] + ' .schedule-contents-event__item-title';
    let period = $(myRoom).text();
    let startDate = period.substr(0, period.indexOf('〜'));
    let endDate = period.substr(period.indexOf('〜') + 1);
    let startDateArr = startDate.match(/(\d+)年(\d+)月(\d+)日/);
    let startYear = startDateArr[1];
    let startMonth = startDateArr[2];
    let startDay = startDateArr[3];
    let monthTop = '.-month' + startMonth;
    let posSt = $(monthTop).offset();
    let endDateArr = endDate.match(/(\d+)年(\d+)月(\d+)日/);
    let endYear = endDateArr[1];
    let endMonth = endDateArr[2];
    let endDay = endDateArr[3];
    let monthEnd = '.-month' + endMonth;
    let posEnd = $(monthEnd).offset();
    let liHeight,oneDay,heightPlus,endLiHeight,endOneDay,endHeightPlus;

    let kongetsu = $('.schedule-contents-main__chart-list li').eq(0).find('.schedule-contents-main__chart-list-item__month').text();
    kongetsu = kongetsu.replace("月", "");
    kongetsu = Number(kongetsu);

    //今月の1日を取得
    let startMonth1st = moment().startOf('month').format("YYYY/MM/DD")
    //開催日
    let dateTo = moment(`${startYear}-${startMonth}-${startDay}`)
    //終了日
    let dateFrom = moment(`${endYear}-${endMonth}-${endDay}`)
    //開催日から終了日までの日数差分
    let beforeDateDiff = dateTo.diff(startMonth1st, 'days') 
    let dateDiff = dateFrom.diff(dateTo, 'days') 
    
    //今月の1日より終了日が前の時の日数差分
    let afterDateDiff = dateFrom.diff(startMonth1st, 'days') 

    //月のliの高さ
    let rowHeight = document.querySelector(".schedule-contents-main__chart-list-item").clientHeight + 1


    //開始日位置取得・指定
    if(posSt.top > posEnd.top && Number(startYear) < Number(year)){
      let periodFirst = $('.schedule-contents-main__chart-list li').eq(0).offset();
      posSt.top = periodFirst.top - 5;
      $(this).offset({top: posSt.top});
    } else if(startYear ==  year && Number(startMonth) < kongetsu){
      //年は今年、かつ今月を過ぎてる開始日
      let periodFirst = $('.schedule-contents-main__chart-list li').eq(0).offset();
      posSt.top = periodFirst.top - 5;
      $(this).offset({top: posSt.top});
    }else {
      liHeight = $(monthTop).innerHeight();
      oneDay = liHeight / 31;
      heightPlus = oneDay * startDay;
      posSt.top = posSt.top + heightPlus;
      $(this).offset({top: posSt.top});
    }


    //タイトル20文字以上は丸める
    let title = $(myTitle).text();
    const max_length = 25;
    let modStr = '';
    if(title.length > max_length){
      modStr = title.substr(0, max_length) + '...';
      $(myTitle).text(modStr);
    }

    //月のliを31分割、1日の高さ計算
    const rate = 31;
    let oneDayHeight = Math.ceil((rowHeight / rate) * 10) / 10    

    //高さを指定
    let totalDaysHeight = oneDayHeight * dateDiff
    let myHeight = beforeDateDiff > 0 ? totalDaysHeight : totalDaysHeight + beforeDateDiff * oneDayHeight
    $(this).css('height',myHeight);

    //開催期間切れは削除
    if(afterDateDiff < 0) {
      $(this).css("display","none");
    }
    
  });

},false);





/*--------------------------------
リサイズ時
---------------------------------*/
window.addEventListener('resize',()=>{
  $('.schedule-contents-event__wrap li').each(function(index) {
    $(this).addClass('-event' + index);
    let d = new Date();
    let year = d.getFullYear();
        year = String(year);
    
    let myClass = $(this).attr('class');
    let class_Array = myClass.split(" ");
    let myRoom = '.' + class_Array[2] + ' .schedule-contents-event__item-term';
    let myTitle = '.' + class_Array[2] + ' .schedule-contents-event__item-title';
    let period = $(myRoom).text();
    let startDate = period.substr(0, period.indexOf('〜'));
    let endDate = period.substr(period.indexOf('〜') + 1);
    let startDateArr = startDate.match(/(\d+)年(\d+)月(\d+)日/);
    let startYear = startDateArr[1];
    let startMonth = startDateArr[2];
    let startDay = startDateArr[3];
    let monthTop = '.-month' + startMonth;
    let posSt = $(monthTop).offset();
    let endDateArr = endDate.match(/(\d+)年(\d+)月(\d+)日/);
    let endYear = endDateArr[1];
    let endMonth = endDateArr[2];
    let endDay = endDateArr[3];
    let monthEnd = '.-month' + endMonth;
    let posEnd = $(monthEnd).offset();
    let liHeight,oneDay,heightPlus,endLiHeight,endOneDay,endHeightPlus;

    let kongetsu = $('.schedule-contents-main__chart-list li').eq(0).find('.schedule-contents-main__chart-list-item__month').text();
    kongetsu = kongetsu.replace("月", "");
    kongetsu = Number(kongetsu);

    //今月の1日を取得
    let startMonth1st = moment().startOf('month').format("YYYY/MM/DD")
    //開催日
    let dateTo = moment(`${startYear}-${startMonth}-${startDay}`)
    //終了日
    let dateFrom = moment(`${endYear}-${endMonth}-${endDay}`)
    //開催日から終了日までの日数差分
    let beforeDateDiff = dateTo.diff(startMonth1st, 'days') 
    let dateDiff = dateFrom.diff(dateTo, 'days') 
    
    //今月の1日より終了日が前の時の日数差分
    let afterDateDiff = dateFrom.diff(startMonth1st, 'days') 

    //月のliの高さ
    let rowHeight = document.querySelector(".schedule-contents-main__chart-list-item").clientHeight + 1


    //開始日位置取得・指定
    if(posSt.top > posEnd.top && Number(startYear) < Number(year)){
      let periodFirst = $('.schedule-contents-main__chart-list li').eq(0).offset();
      posSt.top = periodFirst.top - 5;
      $(this).offset({top: posSt.top});
    } else if(startYear ==  year && Number(startMonth) < kongetsu){
      //年は今年、かつ今月を過ぎてる開始日
      let periodFirst = $('.schedule-contents-main__chart-list li').eq(0).offset();
      posSt.top = periodFirst.top - 5;
      $(this).offset({top: posSt.top});
    }else {
      liHeight = $(monthTop).innerHeight();
      oneDay = liHeight / 31;
      heightPlus = oneDay * startDay;
      posSt.top = posSt.top + heightPlus;
      $(this).offset({top: posSt.top});
    }


    //タイトル20文字以上は丸める
    let title = $(myTitle).text();
    const max_length = 25;
    let modStr = '';
    if(title.length > max_length){
      modStr = title.substr(0, max_length) + '...';
      $(myTitle).text(modStr);
    }

    //月のliを31分割、1日の高さ計算
    const rate = 31;
    let oneDayHeight = Math.ceil((rowHeight / rate) * 10) / 10    

    //高さを指定
    let totalDaysHeight = oneDayHeight * dateDiff
    let myHeight = beforeDateDiff > 0 ? totalDaysHeight : totalDaysHeight + beforeDateDiff * oneDayHeight
    $(this).css('height',myHeight);

    //開催期間切れは削除
    if(afterDateDiff < 0) {
      $(this).css("display","none");
    }
    
  });
},false);