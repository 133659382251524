
  $(".p-contents-title-sp__button").on("click", function() {
    let class_array = $(this).attr('class').split(" ");;
    if(class_array[1] == "-open"){
      $(this).removeClass("-open");
      $(this).addClass("-close");
    } else if(class_array[1] == "-close") {
      $(this).removeClass("-close");
      $(this).addClass("-open");
    }
    $(this).next().slideToggle();
  });



  


  $(".introduction-contents .p-contents-title-medium").on("click", function() {
    const breakPointAccordion = 768;
    if( breakPointAccordion > window.innerWidth ){//sp
      let class_array = $(this).next().attr('class').split(" ");;
      if(class_array[1] == "-open"){
        $(this).next().removeClass("-open");
        $(this).next().addClass("-close");
      } else if(class_array[1] == "-close") {
        $(this).next().removeClass("-close");
        $(this).next().addClass("-open");
      }
      $(this).next().next().slideToggle();
    }
  });

  

