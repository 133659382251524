import isMobile from "../../plugins/is-mobile.js";
import addEventListenerMultiType from "./multiEventLister.js";

class ToolOverlay {
  constructor(options) {
    this.body = document.querySelector("body");
    this.targets = document.querySelectorAll(".js-overlaywindow");
    this.panel = document.querySelector(".overlaywindow-container");
    this.autoClose = true;
  }

  init() {
    this.attachEvent();
  }

  toggle(target) {
    // target.classList.toggle("is-overlaywindow--show");
    // this.body.classList.add("is-overlaywindow--showPage");
  }
  toggleSP(target) {
    // target.classList.toggle("is-overlaywindow--show");
    this.body.classList.add("is-overlaywindow--showPage");
  }

  attachEvent() {
    
// if($('.js-overlaywindow').length) {
//   document.addEventListener("click", e => {
//     if (!e.target.closest(".js-overlaywindow")) {
//       if (!e.target.closest(".overlaywindow-wrapper")) {
//         $("body,html").css({
//           position: "static",
//           overflow: "visible",
//           height: "auto"
//         });
//         setTimeout(function() {
//           $(".js-overlaywindow").removeClass("is-overlaywindow--show");
//           $(".js-overlaywindow").removeClass("is-current");
//           $(".overlaywindow-container").removeClass("is-overlaywindow--show");
//           $("body").removeClass("is-overlaywindow--showPage");
//         }, 100);
//         // メニューを開いた時点のスクロール位置を反映
//         if($('.is-overlaywindow--showPage').length) {
//           $(window).scrollTop($("html").data("scrTop")); //メニューを開いた位置へ移動
//         }
//       } else {
//       }
//     }
//   });
// }
    for (let target of this.targets) {
      if (!isMobile(768)) {
        addEventListenerMultiType(
          target,
          "mouseenter touchstart",
          () => {
            this.toggle(target);
            this.body.classList.add("is-overlaywindow--showPage");
            // 現在のスクロール位置を取得
            $("html").data("scrTop", $(window).scrollTop());
            $("body,html").css({
              position: "fixed",
              top: -1 * $("html").data("scrTop"),
              height: "100%",
            });
            let number = target.getAttribute("data-overlaywindow");
            $(
              '.overlaywindow-container[data-overlaywindow="' + number + '"] '
            ).addClass("is-overlaywindow--show");
          },
          false
        );
        addEventListenerMultiType(
          target,
          "mouseleave touchend",
          () => {
            this.toggle(target);
            $("body,html").css({
              position: "static",
              overflow: "visible",
              height: "auto",
            });
            let number = target.getAttribute("data-overlaywindow");
            this.body.classList.remove("is-overlaywindow--showPage");
            setTimeout(function () {
              $(
                '.overlaywindow-container[data-overlaywindow="' + number + '"] '
              ).removeClass("is-overlaywindow--show");
              this.body.classList.remove("is-overlaywindow--showPage");
            }, 100);
            // メニューを開いた時点のスクロール位置を反映
            $(window).scrollTop($("html").data("scrTop"));
          },
          false
        );
      } else {
        addEventListenerMultiType(
          target,
          "click",
          (e) => {
            if (!target.classList.contains("is-overlaywindow--show") || e.target.classList.contains('l-header-overlay-search__input')) {
              let number = target.getAttribute("data-overlaywindow");

              $(".js-overlaywindow").removeClass("is-overlaywindow--show");
              $(".js-overlaywindow").removeClass("is-current");

              this.body.classList.add("is-overlaywindow--showPage");

              target.classList.add("is-overlaywindow--show");
              target.classList.add("is-current");

              // 現在のスクロール位置を取得
              $("html").data("scrTop", $(window).scrollTop());
              $("body,html").css({
                position: "fixed",
                top: -1 * $("html").data("scrTop"),
                height: "100%",
              });

              setTimeout(function () {
                $(".overlaywindow-container").removeClass(
                  "is-overlaywindow--show"
                );

                $(
                  '.overlaywindow-container[data-overlaywindow="' +
                    number +
                    '"] '
                ).addClass("is-overlaywindow--show");
              }, 100);
            } else {
              // console.log('aaaaaa');
              // console.log(e.target);
              target.classList.remove("is-overlaywindow--show");

              $("body,html").css({
                position: "static",
                overflow: "visible",
                height: "auto",
              });
              setTimeout(function () {
                $(".js-overlaywindow").removeClass("is-overlaywindow--show");
                $(".js-overlaywindow").removeClass("is-current");
                $(".overlaywindow-container").removeClass(
                  "is-overlaywindow--show"
                );

                // $("body").removeClass("is-overlaywindow--showPage");
              }, 100);
            }
          },
          false
        );
      }
    }
  }
}

const toolOverlay = new ToolOverlay();
toolOverlay.init();
