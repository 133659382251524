import swiper from "swiper";

const breakPointOutline = 768;
let mySwiperOutline;
let outlineSlideCount = $('.outline-contents__gallery-list').find(".swiper-slide").length;

/*--------------------------------
ロード時
---------------------------------*/
window.addEventListener('load',()=>{
  if( breakPointOutline < window.innerWidth ){//pc
    if (mySwiperOutline) {
      mySwiperOutline.destroy();
      mySwiperOutline = undefined;
    } 
  } else {
    if(outlineSlideCount > 1){//sp
      if (mySwiperOutline) {
        return;
      } else {
        mySwiperOutline = new swiper ('.outline-contents__gallery', {
          slidesPerView: 1,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          slidesPerView: "auto",
          loop: true,
          onSlideChangeEnd : (s) => {s.fixLoop();},
          spaceBetween: 20,
          centeredSlides : true,
          disableOnInteraction: true,
          slideToClickedSlide: true,
          pagination: {
            el: '.outline-contents__gallery-pagination',
            clickable: true 
          }
        });
      }
    }
  }
},false);

/*--------------------------------
リサイズ時
---------------------------------*/
window.addEventListener('resize',()=>{

  if( breakPointOutline < window.innerWidth ){//pc
    if (mySwiperOutline) {
      mySwiperOutline.destroy();
      mySwiperOutline = undefined;
    }
  } else {
    if(outlineSlideCount > 1){//sp
      if (mySwiperOutline) {
        return;
      } else {
        mySwiperOutline = new swiper ('.outline-contents__gallery', {
          slidesPerView: 1,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          slidesPerView: "auto",
          loop: true,
          onSlideChangeEnd : (s) => {s.fixLoop();},
          spaceBetween: 20,
          //loopedSlides: 3,
          centeredSlides : true,
          disableOnInteraction: true,
          slideToClickedSlide: true,
          pagination: {
            el: '.outline-contents__gallery-pagination',
            clickable: true 
          }
        });
      }
    }
  }
},false);
