import swiper from "swiper";

const breakPointLending = 768;
let mySwiperLending01;
let lendingSlideCount01 = $('.lending-contents-use__facility-gallery').find(".swiper-slide").length;

let mySwiperLending02;
let lendingSlideCount02 = $('.lending-contents-use__facility-gallery02').find(".swiper-slide").length;

let mySwiperLending03;
let lendingSlideCount03 = $('.lending-contents-use__facility-gallery03').find(".swiper-slide").length;

let mySwiperLending04;
let lendingSlideCount04 = $('.lending-contents-use__facility-gallery04').find(".swiper-slide").length;

/*--------------------------------
ロード時
---------------------------------*/
window.addEventListener('load',()=>{
  if( breakPointLending < window.innerWidth ){//pc
    if (mySwiperLending01) {
      mySwiperLending01.destroy();
      mySwiperLending01 = undefined;
    }
  } else {
    if(lendingSlideCount01 > 1){//sp
      if (mySwiperLending01) {
        return;
      } else {
        mySwiperLending01 = new swiper ('.lending-contents-use__facility-gallery', {
          slidesPerView: 1,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          slidesPerView: "auto",
          loop: true,
          onSlideChangeEnd : (s) => {s.fixLoop();},
          spaceBetween: 20,
          //loopedSlides: 3,
          centeredSlides : true,
          disableOnInteraction: true,
          slideToClickedSlide: true,
          pagination: {
            el: '.lending-contents-use__facility-gallery-pagination',
            clickable: true
          }
        });
      }
    }
  }
},false);

window.addEventListener('load',()=>{
  if( breakPointLending < window.innerWidth ){//pc
    if (mySwiperLending02) {
      mySwiperLending02.destroy();
      mySwiperLending02 = undefined;
    }
  } else {
    if(lendingSlideCount02 > 1){//sp
      if (mySwiperLending02) {
        return;
      } else {
        mySwiperLending02 = new swiper ('.lending-contents-use__facility-gallery02', {
          slidesPerView: 1,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          slidesPerView: "auto",
          loop: true,
          onSlideChangeEnd : (s) => {s.fixLoop();},
          spaceBetween: 20,
          //loopedSlides: 3,
          centeredSlides : true,
          disableOnInteraction: true,
          slideToClickedSlide: true,
          pagination: {
            el: '.lending-contents-use__facility-gallery-pagination02',
            clickable: true
          }
        });
      }
    }
  }
},false);

window.addEventListener('load',()=>{
  if( breakPointLending < window.innerWidth ){//pc
    if (mySwiperLending03) {
      mySwiperLending03.destroy();
      mySwiperLending03 = undefined;
    }
  } else {
    if(lendingSlideCount03 > 1){//sp
      if (mySwiperLending03) {
        return;
      } else {
        mySwiperLending03 = new swiper ('.lending-contents-use__facility-gallery03', {
          slidesPerView: 1,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          slidesPerView: "auto",
          loop: true,
          onSlideChangeEnd : (s) => {s.fixLoop();},
          spaceBetween: 20,
          //loopedSlides: 3,
          centeredSlides : true,
          disableOnInteraction: true,
          slideToClickedSlide: true,
          pagination: {
            el: '.lending-contents-use__facility-gallery-pagination03',
            clickable: true
          }
        });
      }
    }
  }
},false);

window.addEventListener('load',()=>{
  if( breakPointLending < window.innerWidth ){//pc
    if (mySwiperLending04) {
      mySwiperLending04.destroy();
      mySwiperLending04 = undefined;
    }
  } else {
    if(lendingSlideCount04 > 1){//sp
      if (mySwiperLending04) {
        return;
      } else {
        mySwiperLending04 = new swiper ('.lending-contents-use__facility-gallery04', {
          slidesPerView: 1,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          slidesPerView: "auto",
          loop: true,
          onSlideChangeEnd : (s) => {s.fixLoop();},
          spaceBetween: 20,
          //loopedSlides: 3,
          centeredSlides : true,
          disableOnInteraction: true,
          slideToClickedSlide: true,
          pagination: {
            el: '.lending-contents-use__facility-gallery-pagination04',
            clickable: true
          }
        });
      }
    }
  }
},false);


/*--------------------------------
リサイズ時
---------------------------------*/
window.addEventListener('resize',()=>{
  if( breakPointLending < window.innerWidth ){//pc
    if (mySwiperLending01) {
      mySwiperLending01.destroy();
      mySwiperLending01 = undefined;
    }
  } else {
    if(lendingSlideCount01 > 1){//sp
      if (mySwiperLending01) {
        return;
      } else {
        mySwiperLending01 = new swiper ('.lending-contents-use__facility-gallery', {
          slidesPerView: 1,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          slidesPerView: "auto",
          loop: true,
          onSlideChangeEnd : (s) => {s.fixLoop();},
          spaceBetween: 20,
          //loopedSlides: 3,
          centeredSlides : true,
          disableOnInteraction: true,
          slideToClickedSlide: true,
          pagination: {
            el: '.lending-contents-use__facility-gallery-pagination',
            clickable: true
          }
        });
      }
    }
  }
},false);


window.addEventListener('resize',()=>{
  if( breakPointLending < window.innerWidth ){//pc
    if (mySwiperLending02) {
      mySwiperLending02.destroy();
      mySwiperLending02 = undefined;
    }
  } else {
    if(lendingSlideCount02 > 1){//sp
      if (mySwiperLending02) {
        return;
      } else {
        mySwiperLending02 = new swiper ('.lending-contents-use__facility-gallery02', {
          slidesPerView: 1,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          slidesPerView: "auto",
          loop: true,
          onSlideChangeEnd : (s) => {s.fixLoop();},
          spaceBetween: 20,
          //loopedSlides: 3,
          centeredSlides : true,
          disableOnInteraction: true,
          slideToClickedSlide: true,
          pagination: {
            el: '.lending-contents-use__facility-gallery-pagination02',
            clickable: true
          }
        });
      }
    }
  }
},false);


window.addEventListener('resize',()=>{
  if( breakPointLending < window.innerWidth ){//pc
    if (mySwiperLending03) {
      mySwiperLending03.destroy();
      mySwiperLending03 = undefined;
    }
  } else {
    if(lendingSlideCount03 > 1){//sp
      if (mySwiperLending03) {
        return;
      } else {
        mySwiperLending03 = new swiper ('.lending-contents-use__facility-gallery03', {
          slidesPerView: 1,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          slidesPerView: "auto",
          loop: true,
          onSlideChangeEnd : (s) => {s.fixLoop();},
          spaceBetween: 20,
          //loopedSlides: 3,
          centeredSlides : true,
          disableOnInteraction: true,
          slideToClickedSlide: true,
          pagination: {
            el: '.lending-contents-use__facility-gallery-pagination03',
            clickable: true
          }
        });
      }
    }
  }
},false);

window.addEventListener('resize',()=>{
  if( breakPointLending < window.innerWidth ){//pc
    if (mySwiperLending04) {
      mySwiperLending04.destroy();
      mySwiperLending04 = undefined;
    }
  } else {
    if(lendingSlideCount04 > 1){//sp
      if (mySwiperLending04) {
        return;
      } else {
        mySwiperLending04 = new swiper ('.lending-contents-use__facility-gallery04', {
          slidesPerView: 1,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          slidesPerView: "auto",
          loop: true,
          onSlideChangeEnd : (s) => {s.fixLoop();},
          spaceBetween: 20,
          //loopedSlides: 3,
          centeredSlides : true,
          disableOnInteraction: true,
          slideToClickedSlide: true,
          pagination: {
            el: '.lending-contents-use__facility-gallery-pagination04',
            clickable: true
          }
        });
      }
    }
  }
},false);
