var pathname = function () {
  return location.pathname == "/index.php" ? "/" : location.pathname;
};

if (!$(".en-lang").length && !$(".kr-lang").length) {
  $(".l-headder-nav-list__item a").each(function () {
    var $href = $(this).attr("href");
    if (location.href.match($href) && $href != "/") {
      $(this).addClass("is-current");
    }
  });
} else {
  $(".l-headder-nav-list__item a").each(function () {
    var $href = $(this).attr("href").split("/")[2];
    if (location.pathname.split("/")[2] == $href && $href != "/") {
      $(this).addClass("is-current");
    }
  });
}
