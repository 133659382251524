import swiper from "swiper";

//--------------------------------------------------------
//top-heroスライド
//--------------------------------------------------------

let slider = document.querySelector(".js-search-single-main");

if (slider) {
  var slideCount = slider.querySelectorAll(".swiper-slide").length;
}

if (slideCount > 1) {
  const searchMain = new swiper(".js-search-single-main", {
    direction: "horizontal",
    loop: false,
    speed: 1500,
    // autoplay: true,
    spaceBetween: 0,
    centeredSlides: true,
    observer: true,
    allowTouchMove: true,
    preloadImages: false,
    autoHeight: true,
    slidesPerView: 1,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 2,
    },
    breakpoints: {
      // 414: {
      //   autoHeight: false,
      //   slidesPerView: 1
      // }
    },
    navigation: {
      nextEl: ".p-search-single-slider-main-next",
      prevEl: ".p-search-single-slider-main-prev",
    },
  });

  const searchSub = document
    .querySelector(".js-search-single-sub")
    .querySelectorAll(".p-search-single-slider-sub__item");

  searchSub.forEach((e, i) => {
    e.addEventListener("click", () => {
      searchMain.slideTo(i + 1);
    });
  });
} else if(slider || slideCount < 1) {
  document.querySelector(".p-search-single-slider-main-next").style.display =
    "none";
  document.querySelector(".p-search-single-slider-main-prev").style.display =
    "none";

  document.querySelector(".js-search-single-sub").style.display = "none";
}
